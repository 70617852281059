import React from 'react'

const Blog = () => {
  return (
    <div className='blo'>
      <div className='bor'>
      <div className='imgfdajhx'>
           <a href=""><img className='ico' src="https://s3.amazonaws.com/tf-nightingale/2020/07/001-Thinkful-Best-Practices-Coding-Blog-1080x1080.png" alt="blog" /></a>
        <p>adipisicing elit. Ad voluptatum autem ipsum mollitia adipisci impedit pariatur odio, placeat reiciendis velit eligendi! Maxime, obcaecati ratione vel nisi libero quo cumque deleniti.</p>
        </div>
      </div>
        <div className='imgfdahx' >
            <a href=""><img className='vrd' src="https://www.codiga.io/_next/static/media/background-default.1657f1f1.jpg" alt="" /></a>
        <p>ipsum dolor sit amet consectetur adipisicing elit. Sapiente, eligendi, natus mollitia exercitationem ducimus nemo nam et repellat earum aut sed! Earum dolorem quae, itaque eos cumque amet illum natus?</p>
        </div>
        <div>
            <a href=""><img src="https://j4r8d7d7.rocketcdn.me/wp-content/uploads/2021/09/WordPress-Content-Management-System.png" alt="" /></a>
        </div>
    </div>
  )
}

export default Blog