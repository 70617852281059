import React from 'react'
import Info from './Info'
import Banner from './Banner'
import Slider from './Slider'


const Home = () => {
    return (
        <div>
            <Banner/>
            <Info/>
            <Slider/>
        
        </div>
    )
}

export default Home